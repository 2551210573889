import {
  Button,
  Card,
  Container,
  createStyles,
  Group,
  Indicator,
  Modal,
  Popover,
  Stack,
  useMantineTheme,
} from "@mantine/core";
import VacancySelect from "./filters/VacancySelect";
import EncumberedSelect from "./filters/EncumberedSelect";
import OwnerSelect from "./filters/OwnerSelect";
import BedsSelect from "./filters/BedsSelect";
import RentSelect from "./filters/RentSelect";
import {
  IconBed,
  IconDoorEnter,
  IconFilter,
  IconFilterOff,
  IconHomeDollar,
  IconHomeStats,
  IconTag,
} from "@tabler/icons-react";
import { useContext, useState } from "react";
import { mapDispatchContext } from "../context/mapContext";
import { AddressSelect } from "./filters/AddressSelect";
import { useMediaQuery } from "@mantine/hooks";
import RentalStats from "./RentalStats";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  filterButton: {
    color:
      theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.white,

    borderColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.grape[9],
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.grape[6],
  },
  resetButton: {
    color:
      theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.white,
    borderColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.grape[9],
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[9]
        : theme.colors.grape[9],
    "&[disabled]": {
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[5],
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2],
    },
  },
}));

/** Button component that displays a particular filter control when you click on it
 * Currently used on desktop only */
const FilterPopover = ({ label, filter, icon, filterString }) => {
  const { classes } = useStyles();
  const { thisFilterSet } = useContext(mapDispatchContext);
  const disabled = !thisFilterSet(filterString);
  return (
    <Popover trapFocus width={300}>
      <Popover.Target>
        <Indicator disabled={disabled} color="olive">
          <Button
            leftIcon={icon}
            variant={"filled"}
            className={classes.filterButton}
          >
            {label}
          </Button>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown>{filter}</Popover.Dropdown>
    </Popover>
  );
};

/** Button component that displays stats when you click on it
 * Currently used on mobile only */
const StatsPopover = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Popover trapFocus>
      <Popover.Target>
        <Button
          className={classes.filterButton}
          compact
          leftIcon={<IconHomeStats />}
        >
          {t("buttons.stats")}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <RentalStats />
      </Popover.Dropdown>
    </Popover>
  );
};

/** Button component that resets filters when you click on it
 * Disabled if no filters are set */
const ResetFilters = ({ compact }) => {
  const { resetFilters, filtersSet } = useContext(mapDispatchContext);
  const disabled = !filtersSet();
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Button
      leftIcon={<IconFilterOff />}
      disabled={disabled}
      onClick={resetFilters}
      className={classes.resetButton}
      compact={compact}
    >
      {t("filters.buttons.clear")}
    </Button>
  );
};

/** Stack component that includes all filter controls other than OwnerSelect and AddressSelect */
const RentalFiltersStack = () => {
  return (
    <Stack>
      <Card withBorder shadow="sm">
        <VacancySelect />
      </Card>
      <Card withBorder shadow="sm">
        <EncumberedSelect />
      </Card>
      <Card withBorder shadow="sm">
        <BedsSelect />
      </Card>
      <Card withBorder shadow="sm">
        <RentSelect />
      </Card>
    </Stack>
  );
};

/** Group component that includes owner/address controls, a button for stats and a button for filters
 * Currently only used on mobile
 */
const CompactFilterButtons = () => {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Modal
        title={t("filters.title")}
        opened={opened}
        onClose={() => setOpened(false)}
        size="auto"
      >
        <RentalFiltersStack />
      </Modal>
      <Group>
        <Stack align="flex-start">
          <AddressSelect />
          <OwnerSelect label={false} />
          <StatsPopover />
          <Button
            className={classes.filterButton}
            compact
            leftIcon={<IconFilter />}
            onClick={() => setOpened(true)}
          >
            {t("buttons.filters")}
          </Button>
          <ResetFilters compact />
        </Stack>
      </Group>
    </>
  );
};

/** Group component that includes owner/address controls, a button for stats and
 * separate FilterPopover buttons for each filter
 * Currently only used on desktop
 */
const ExpandedFilterButtons = () => {
  const { t } = useTranslation();
  return (
    <Group>
      <Stack>
        <AddressSelect />
        <OwnerSelect label={false} />
      </Stack>
      <Stack>
        <Container>
          <Group>
            <FilterPopover
              label={t("filters.buttons.price")}
              filter={<RentSelect />}
              icon={<IconTag />}
              filterString={"rent"}
            />
            <FilterPopover
              label={t("filters.buttons.affordability")}
              filter={<EncumberedSelect />}
              icon={<IconHomeDollar />}
              filterString={"enc"}
            />
            <FilterPopover
              label={t("filters.buttons.beds")}
              filter={<BedsSelect />}
              icon={<IconBed />}
              filterString={"beds"}
            />
          </Group>
        </Container>
        <Container>
          <Group>
            <FilterPopover
              label={t("filters.buttons.vacancy")}
              filter={<VacancySelect />}
              icon={<IconDoorEnter />}
              filterString={"vac"}
            />
            <ResetFilters />
          </Group>
        </Container>
      </Stack>
    </Group>
  );
};

/** Component that floats over the Map and provides filter/stat controls.
 * Responsive to mobile/desktop.
 */
export const RentalFiltersGrid = () => {
  const theme = useMantineTheme();
  const mediaQuery = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);
  if (mediaQuery) {
    return <ExpandedFilterButtons />;
  } else {
    return <CompactFilterButtons />;
  }
};
