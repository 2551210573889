import { createStyles, Group, Header, Text, Title } from "@mantine/core";
import { IconBuildingEstate } from "@tabler/icons-react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AboutButton } from "./About";
import ColorSchemeToggle from "./ColorSchemeToggle";

const useStyles = createStyles((theme) => ({
  headerLink: {
    textDecoration: "none",
    color: theme.colors.grape[9],
  },

  articleLink: {
    [theme.fn.smallerThan("xl")]: {
      display: "none",
    },
  },
}));

const HeaderMenu = ({ className, height, update_date }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Header height={height} className={className}>
      <Group sx={{ height: "100%" }} px={20} position="apart">
        <Link to="/" className={classes.headerLink}>
          <Title order={2}>
            <IconBuildingEstate /> {t("logo_title")}
          </Title>
        </Link>
        <Group>
          <Text truncate className={classes.articleLink}>
            <a
              rel="external noopener noreferrer"
              target="_blank"
              href="https://medium.com/@hughfitzgerald/rents-charged-by-landlords-should-be-made-public-lets-start-with-culver-city-d224b7740b06"
            >
              Rents charged by landlords should be made public — Let's start
              with Culver City...
            </a>
          </Text>
          <Text>
            <Trans i18nKey="data_date">
              Data Updated: {{ update_date: update_date }}
            </Trans>
          </Text>
          <AboutButton variant="outline" size={29} update_date={update_date} />
          <ColorSchemeToggle variant="outline" size={29} />
        </Group>
      </Group>
    </Header>
  );
};

export default HeaderMenu;
