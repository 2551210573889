import { Chip, Divider, Group, Stack } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mapContext, mapDispatchContext } from "../../context/mapContext";
import FilterInfo from "./FilterInfo";

export const VacancySelect = () => {
  const { defVacancy, searchParams, reactSearchParams } =
    useContext(mapContext);
  const { setSearchParams } = useContext(mapDispatchContext);
  const [vacancyValues, setVacancyValues] = useState(defVacancy);
  const { t } = useTranslation();

  function updateVacancy(vacancyValues) {
    if (!vacancyValues.length) {
      setSearchParams({ vac: ["none"] });
    } else {
      setSearchParams({ vac: vacancyValues });
    }
    setVacancyValues(vacancyValues);
  }

  useEffect(() => {
    setVacancyValues(searchParams["vac"]);
    //eslint-disable-next-line
  }, [reactSearchParams]);

  return (
    <Stack spacing="xs">
      <Group position="apart">
      {t('filters.headings.vacancy')}{" "}
        <FilterInfo infoText={t('filters.info.vacancy')} />
      </Group>
      <Divider />
      <Group position="center">
        <Chip.Group
          position="center"
          multiple
          mt={15}
          value={vacancyValues}
          onChange={updateVacancy}
        >
          <Chip value="rented" variant="filled">
            {t('chips.rented')}
          </Chip>
          <Chip value="vacant" variant="filled">
            {t('chips.vacant')}
          </Chip>
        </Chip.Group>
      </Group>
    </Stack>
  );
};

export default VacancySelect;
