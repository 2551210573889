import { Chip, Stack, Group, Divider } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mapContext, mapDispatchContext } from "../../context/mapContext";
import FilterInfo from "./FilterInfo";

export const BedsSelect = () => {
  const { defBeds, searchParams, reactSearchParams } = useContext(mapContext);
  const { setSearchParams } = useContext(mapDispatchContext);
  const [bedsValues, setBedsValues] = useState(defBeds);
  const { t } = useTranslation();

  function updateBeds(bedsValues) {
    if (!bedsValues.length) {
      setSearchParams({ beds: ["none"] });
    } else {
      setSearchParams({ beds: bedsValues });
    }

    setBedsValues(bedsValues);
  }

  useEffect(() => {
    setBedsValues(searchParams["beds"]);
    //eslint-disable-next-line
  }, [reactSearchParams]);

  return (
    <Stack spacing="xs">
      <Group position="apart">
        {t("filters.headings.beds")}{" "}
        <FilterInfo infoText={t("filters.info.beds")} />
      </Group>
      <Divider />
      <Group position="center">
        <Chip.Group multiple mt={15} value={bedsValues} onChange={updateBeds} position="center">
          <Chip value="0" variant="filled">
            {t("chips.studio")}
          </Chip>
          <Chip value="1" variant="filled">
            {t("chips.1bed")}
          </Chip>
          <Chip value="2" variant="filled">
            {t("chips.2bed")}
          </Chip>
          <Chip value="3" variant="filled">
            {t("chips.3bed")}
          </Chip>
          <Chip value="4" variant="filled">
            {t("chips.4bed")}
          </Chip>
          <Chip value="5" variant="filled">
            {t("chips.5bed")}
          </Chip>
        </Chip.Group>
      </Group>
    </Stack>
  );
};

export default BedsSelect;
