import { Chip, Divider, Group, Stack } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mapContext, mapDispatchContext } from "../../context/mapContext";
import FilterInfo from "./FilterInfo";

export const EncumberedSelect = () => {
  const { defEnc, searchParams, reactSearchParams } = useContext(mapContext);
  const { setSearchParams } = useContext(mapDispatchContext);
  const [encValues, setEncValues] = useState(defEnc);
  const { t } = useTranslation();

  function updateEnc(encValues) {
    if (!encValues.length) {
      setSearchParams({ enc: ["none"] });
    } else {
      setSearchParams({ enc: encValues });
    }

    setEncValues(encValues);
  }

  useEffect(() => {
    setEncValues(searchParams["enc"]);
    //eslint-disable-next-line
  }, [reactSearchParams]);

  return (
    <Stack spacing="xs">
      <Group position="apart">
        {t("filters.headings.affordability")}{" "}
        <FilterInfo infoText={t("filters.info.affordability")} />
      </Group>
      <Divider />
      <Group position="center">
        <Chip.Group
          position="center"
          multiple
          mt={15}
          value={encValues}
          onChange={updateEnc}
        >
          <Chip value="affordable" variant="filled">
            {t("chips.restricted")}
          </Chip>
          <Chip value="market" variant="filled">
            {t("chips.unrestricted")}
          </Chip>
        </Chip.Group>
      </Group>
    </Stack>
  );
};

export default EncumberedSelect;
